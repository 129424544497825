import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  const { t } = useTranslation(); 

  const styles = {
    footer: {
      backgroundColor: '#10381f',
      color: '#fff',
      padding: '2rem 0',
      fontFamily: 'Corbel, sans-serif',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 1rem',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      textAlign: 'center',
    },
    column: {
      flex: '1',
      minWidth: '150px',
      marginBottom: '1rem',
    },
    heading: {
      marginBottom: '1rem',
      fontSize: '1.2rem',
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    listItem: {
      marginBottom: '0.5rem',
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
    },
    socialIcons: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '1rem',
    },
    icon: {
      fontSize: '1.5rem',
      margin: '0 0.5rem',
    },
    copyright: {
      borderTop: '1px solid #444',
      paddingTop: '1rem',
      marginTop: '1rem',
    },
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.row}>
          <div style={styles.column}>
            <h5 style={styles.heading}>{t('footer.our_collection')}</h5>
            <ul style={styles.list}>
              <li style={styles.listItem}><Link to="/watches" style={styles.link}>{t('footer.watches')}</Link></li>
            </ul>
          </div>
          <div style={styles.column}>
            <h5 style={styles.heading}>{t('footer.about_staff')}</h5>
            <ul style={styles.list}>
              <li style={styles.listItem}><Link to="/about" style={styles.link}>{t('footer.about_us')}</Link></li>
              <li style={styles.listItem}><Link to="/" style={styles.link}>{t('footer.home_page')}</Link></li>
            </ul>
          </div>
          <div style={styles.column}>
            <h5 style={styles.heading}>{t('footer.follow_us')}</h5>
            <div style={styles.socialIcons}>
              <a href="mailto:zagrebluxurywatches@gmail.com" target="_blank" rel="noopener noreferrer" style={styles.link}>
                <i className="fas fa-envelope" style={styles.icon}></i>
              </a>
              <a href="https://www.instagram.com/timelesswatcheszagreb?igsh=MTdjdzQzeGh3MTViZA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" style={styles.link}>
                <i className="fab fa-instagram" style={styles.icon}></i>
              </a>
            </div>
          </div>
          <div style={styles.column}>
            <h5 style={styles.heading}>{t('footer.need_assistance')}</h5>
            <ul style={styles.list}>
              <li style={styles.listItem}><Link to="/contact" style={styles.link}>{t('footer.contact')}</Link></li>
            </ul>
          </div>
        </div>
        <div style={styles.copyright}>
          <p>&copy; 2024 Zagreb Luxury Watches. {t('footer.copyright')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
